<script setup lang="ts">
</script>

<template>
  <div
    class="flex w-full max-w-5xl space-x-6"
  >
    <div class="flex w-full flex-col space-y-0.5">
      <h1
        class="text-center font-display text-2xl text-zinc-50 md:h-11 md:text-4xl"
      >
        <span>
          <span class="md:inline">Repetir mi pedido a domicilio</span>
        </span>
      </h1>
    </div>
  </div>
</template>
