<template>
  <div class="relative flex w-full justify-center overflow-hidden rounded-2xl bg-hg-neutral-600 object-cover md:items-center">
    <img
      class=" absolute -left-3 -top-0 z-0 h-20 w-20 scale-105 -scale-x-105 object-cover md:scale-150 md:-scale-x-150"
      :src="require('assets/images/ray-transparent-background.png')"
    >
    <div class="z-10 inline-block ">
      <slot />
    </div>
    <img
      class="absolute -right-3 -top-0 z-0 h-20 w-20 scale-105 object-cover md:scale-150"
      :src="require('assets/images/ray-transparent-background.png')"
    >
  </div>
</template>
