<script setup lang="ts">

</script>
<template>
  <div class="mt-12 flex w-full flex-col-reverse items-center space-y-10 space-y-reverse bg-hg-neutral-900 px-16 py-14 md:mt-36 md:h-52 md:flex-row md:justify-between md:space-y-0 md:py-0">
    <a
      href="/"
    >
      <img
        class="md:h-32"
        :src="require('assets/images/logo.svg')"
        alt="HAY Gas! logo"
      >
    </a>
    <div
      class="flex flex-col items-center space-y-7"
    >
      <a
        class="text-2xl text-white"
        href="mailto:equipo@haygas.cl"
      >
        equipo@haygas.cl
      </a>
      <div
        class="flex items-center justify-center space-x-7 md:space-x-4"
      >
        <a
          href="https://api.whatsapp.com/send/?phone=56958703442&text=¡Hola!&type=phone_number&app_absent=0"
          target="_blank"
          rel="noopener"
        >
          <img
            :src="require('assets/images/whatsapp.svg')"
            class="h-12"
          >
        </a>
        <a
          href="https://www.facebook.com/Hay-Gas-106570052264202"
          target="_blank"
          rel="noopener"
        >
          <img :src="require('assets/images/facebook.svg')">
        </a>
        <a
          href="https://www.instagram.com/haygas.cl/"
          target="_blank"
          rel="noopener"
        >
          <img :src="require('assets/images/instagram.svg')">
        </a>
        <a
          href="https://twitter.com/haygas_cl"
          target="_blank"
          rel="noopener"
        >
          <img :src="require('assets/images/twitter.svg')">
        </a>
      </div>
    </div>
  </div>
</template>
