<script setup lang="ts">
</script>
<template>
  <div class="flex w-full flex-col gap-4 rounded-3xl bg-hg-neutral-900/70 p-8 md:py-12">
    <p class="text-center font-display text-3xl uppercase md:text-3xl">
      Sigue tu pedido
    </p>
    <p class="text-base md:text-2xl">
      Si ya pediste, haz click para conocer el <strong>estado de tu pedido</strong> y saber cuándo lo recibirás!
    </p>
    <base-button
      variant="primary"
      size="md"
      type="button"
      href="/seguimiento"
      class="mx-auto font-semibold"
    >
      Seguir mi pedido
    </base-button>
  </div>
</template>
